<template>
    <div></div>
</template>

<script>
import store from "./store/index"
export default {
    created() {
        if(!this.$store.hasModule('dashboard'))
            this.$store.registerModule("dashboard", store)
    }
}
</script>